import React from "react";
import Helmet from "react-helmet";
import { Prompt } from "react-router";
import { isDemoFromHost } from "../../../utils/DomainHelpers";
import Loading from "../../components/Loading/Loading";
import { Menu } from "../../contentModules/Menu/Menu";
import { ShowModule } from "../../contentModules/ShowModule";
import { IconDesign } from "../../icons/design";
import { IconEdit } from "../../icons/edit";
import { IconPreview } from "../../icons/preview";
import { IconSave } from "../../icons/save";
import { IModule } from "../../interfaces/Module";
import { enumTheme, IColorScheme, IGiftRegistryReservations, IImage, ISite } from "../../interfaces/Site";
import { ActionBar } from "../../modules/actionBar/ActionBar";
import { ActionBarItem } from "../../modules/actionBar/components/ActionBarItem/ActionBarItem";
import AddModule from "../../modules/addModule/AddModule";
import Confirmation from "../../modules/confirmation/Confirmation";
import { EditDesign } from "../../modules/EditDesign/EditDesign";
import { EditMenu } from "../../modules/EditMenu/EditMenu";
import "../../style/variables.scss";
import { getBrand } from "../../utils/getBrand";
import { addModule, deleteModule, moveModule } from "../../utils/ModuleCollection";
import {
    setGeneratorColorStylesVariables,
    setGeneratorFontsStylesVariables,
} from "../../utils/SetGeneratorStylesVariables";
import { findUniqueSlug, slugify } from "../../utils/Slug";
import { getColorFromSite } from "../../utils/Style";
import styles from "./Home.module.scss";
import themeStyles from "./ThemeStyles.module.scss";
import { SiteGraphic, TSiteGraphic } from "../../components/SiteGraphic/SiteGraphic";
import { IDesignTemplate } from "../../modules/EditDesignTemplate/utils/designTemplates";
import { loadSiteFonts } from "../../utils/LoadSiteFonts";
import { IconToggleContentModuleActionBar } from "../../icons/toggleContentModuleActionBar";
import { IconGlobe } from "../../icons/globe";
import { EditMore } from "../GeneratorEdit/modules/EditMore/EditMore";
import { TFontHeading, TFontText } from "../../enums/font";

interface IState {
    showAddModule: boolean;
    addModuleBeforeThisModule: IModule | null | undefined;
    showEditMenu: boolean;
    showDeleteModuleConfirmation: boolean;
    moduleToDelete?: IModule;
    openDrawer: "more" | "design" | null;
    loading: boolean;
    showPublish: boolean;
}

interface IProps {
    editAvailable: boolean;
    site: ISite;
    updateSite?: (site: ISite, dontTriggerDirty?: boolean) => void;
    editMode: boolean;
    showError: any;
    quickStart: boolean;
    toggleEditMode?: any;
    showHelpFn?: any;
    showHelp?: boolean;
    isDirty?: boolean;
    routerHistory: any;
    setSiteInitial?: (site: ISite) => void;
    setIsDirty?: (isDirty: boolean) => void;
    drawerSlotPortalNode?: JSX.Element;
    updateGiftRegistryReservations: (giftRegistryReservations: IGiftRegistryReservations[]) => void;
    saveOrCreateSite?: () => void;
}

export class Home extends React.Component<IProps, IState> {
    state: IState = {
        showAddModule: false,
        showEditMenu: false,
        showDeleteModuleConfirmation: false,
        openDrawer: null,
        loading: false,
        showPublish: false,
        addModuleBeforeThisModule: null,
    };

    componentDidMount() {
        window.addEventListener("beforeunload", (e) => {
            if (this.props.isDirty) {
                // Cancel the event
                e.preventDefault();
                // Chrome requires returnValue to be set
                e.returnValue = "";
                // the absence of a returnValue property on the event will guarantee the browser unload happens
                delete e["returnValue"];
            }
        });
    }

    closeEditMenu = () => this.setState({ showEditMenu: false });
    showEditMenu = () => this.setState({ showEditMenu: true });

    updateMenuItem = (module: IModule, menuTitle: string) => {
        const site = this.props.site;
        module.menuTitle = menuTitle;

        let slug = slugify(menuTitle);
        // If menuTitle is empty or only spaces that are stripped in slugify we need to give it a value
        if (slug === "") {
            slug = "_";
        }
        slug = findUniqueSlug(slug, this.props.site.modules, module);
        module.slug = slug;

        this.props.updateSite!(site);
    };

    updateHideInMenu = (module: IModule, hideInMenu: boolean) => {
        const site = this.props.site;
        module.hideInMenu = hideInMenu;
        this.props.updateSite!(site);
    };

    addOrUpdateImageToSite = (image: IImage) => {
        let site = this.props.site;
        const imageIndexToUpdate = site.images.findIndex((found) => found.id === image.id);
        if (imageIndexToUpdate > -1) {
            site.images[imageIndexToUpdate] = image;
        } else {
            site.images.push(image);
        }
        this.props.updateSite!(site);
    };

    updateProperty = (module: IModule, property: string, value: any) => {
        let site = this.props.site;

        if (typeof value === "string" || typeof value === "boolean" || typeof value === "number") {
            if (module.properties[property] !== value) {
                module.properties[property] = value;
                this.props.updateSite!(site);
            }
        } else if (value === null) {
            delete module.properties[property];
            this.props.updateSite!(site);
        } else {
            module.properties[property] = value;
            this.props.updateSite!(site);
        }
    };

    updateReceiverMail = (receiverCategory: "rsvp" | "speech", mail: string[]) => {
        let site = this.props.site;
        site.mailReceivers![receiverCategory] = mail;
        this.props.updateSite!(site);
    };

    showAddModule = (moduleToPutNewModelBefore?: IModule) => {
        this.setState({
            showAddModule: true,
            addModuleBeforeThisModule: moduleToPutNewModelBefore,
        });
    };

    moveModule = (moduleToMove: IModule, indexAdjustment: number) => {
        const site = this.props.site;
        site.modules = moveModule(site.modules, moduleToMove, indexAdjustment);
        this.props.updateSite!(site);

        setTimeout(() => {
            const moduleElement = document.querySelector("#" + moduleToMove.slug);

            if (moduleElement) {
                const yOffset = -100; // To get aware of the movment/context as user
                const y = moduleElement.getBoundingClientRect().top + window.scrollY + yOffset;

                window.scrollTo({ top: y, behavior: "smooth" });
            }
        });
    };

    showOrHideModule = (module: IModule, hide: boolean) => {
        const site = this.props.site;
        const index = site.modules.indexOf(module);
        site.modules[index].hide = hide;
        this.props.updateSite!(site);
    };

    confirmDeleteModule = (moduleToDelete: IModule) => {
        this.setState({
            showDeleteModuleConfirmation: true,
            moduleToDelete,
        });
    };

    deleteModule = () => {
        const site = this.props.site;
        if (this.props.site) {
            site.modules = deleteModule(site.modules, this.state.moduleToDelete!);
        }
        this.props.updateSite!(site);
    };

    addNewModule = (moduleType: string, moduleToPutNewModelBefore?: IModule) => {
        const site = this.props.site;

        let newModuleData = addModule(site.modules, moduleType, moduleToPutNewModelBefore);

        site.modules = newModuleData.modules;
        this.props.updateSite!(site);

        const newModuleSlug = newModuleData.newModule.slug;
        setTimeout(() => {
            const newModuleElement = document.querySelector("#" + newModuleSlug);
            if (newModuleElement) {
                newModuleElement.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        });
    };

    closeAddModule = () => {
        this.setState({
            showAddModule: false,
            addModuleBeforeThisModule: null,
        });
    };

    listModules() {
        if (this.props.site) {
            return this.props.site.modules
                .filter((module) => module.hide !== true || this.props.editMode)
                .map((module, index) => (
                    <ShowModule
                        updateProperty={this.updateProperty.bind(this)}
                        showOrHideModule={this.showOrHideModule.bind(this)}
                        showError={this.props.showError}
                        addOrUpdateImageToSite={this.addOrUpdateImageToSite.bind(this)}
                        editAvailable={this.props.editAvailable}
                        site={this.props.site}
                        editMode={this.props.editMode}
                        key={module.id}
                        moduleData={module}
                        showAddModule={this.showAddModule}
                        deleteModule={this.confirmDeleteModule}
                        moveModule={this.moveModule}
                        updateReceiverMail={this.updateReceiverMail}
                        updateGiftRegistryReservations={this.props.updateGiftRegistryReservations}
                    />
                ));
        } else {
            return null;
        }
    }

    closeConfirmationCallback = () => this.setState({ showDeleteModuleConfirmation: false });

    setColors = (colors: IColorScheme) => {
        let site = this.props.site;

        site.colorScheme = colors;

        setGeneratorColorStylesVariables(colors);
        this.props.updateSite!(site);
    };

    setFont = (font: TFontHeading | TFontText, fontType: "heading" | "text") => {
        let site = this.props.site;

        if (fontType === "heading") {
            site.fonts.heading = font as TFontHeading;
        } else if (fontType === "text") {
            site.fonts.text = font as TFontText;
        }

        setGeneratorFontsStylesVariables(site.fonts);
        this.props.updateSite!(site);
    };

    setTheme = (theme: enumTheme) => {
        let site = this.props.site;
        site.theme = theme;
        this.props.updateSite!(site);
    };

    setSiteGraphic = (siteGraphic: TSiteGraphic | null) => {
        let site = this.props.site;
        site.siteGraphic = siteGraphic;
        this.props.updateSite!(site);
    };

    setDesignTemplate = (designTemplate: IDesignTemplate) => {
        let site = this.props.site;
        site.theme = designTemplate.theme;
        site.fonts.heading = designTemplate.fonts.heading;
        site.fonts.text = designTemplate.fonts.text;
        setGeneratorFontsStylesVariables(designTemplate.fonts);
        loadSiteFonts(designTemplate.fonts);
        site.siteGraphic = designTemplate.siteGraphic;
        site.colorScheme = designTemplate.colorScheme;
        setGeneratorColorStylesVariables(designTemplate.colorScheme);
        site.mostRecentSelectedDesignTemplate = designTemplate.name;

        this.props.updateSite!(site);
    };

    closeDrawer = () => this.setState({ openDrawer: null });

    showPublishMenuOption = () => this.props.quickStart !== true && this.props.site.active !== true;

    setAndReturnTitle = () => {
        let title;
        if (this.props.editAvailable === false && !isDemoFromHost(this.props.site.host)) {
            title = this.props.site.host;
        } else if (this.props.editAvailable === false && isDemoFromHost(this.props.site.host)) {
            title = "Demosida - " + getBrand();
        } else {
            title = "Prova gratis utan registrering - " + getBrand();
        }
        window.document.title = title;
        return title;
    };

    getMetaRobots = () => {
        if (this.props.site.metaNoindex === true) {
            return "noindex, noimageindex, nofollow";
        } else {
            return "all";
        }
    };

    render() {
        return (
            <div className={`${styles[this.props.site.theme]} ${themeStyles[this.props.site.theme]}`}>
                <Helmet>
                    <title>{this.setAndReturnTitle()}</title>
                    <style type="text/css">{`
                        body {
                            background-color: ${getColorFromSite(this.props.site, "background")};
                        }
                    `}</style>
                    <meta name="theme-color" content={getColorFromSite(this.props.site, "background")} />
                    <meta name="robots" content={this.getMetaRobots()} />
                </Helmet>
                {this.props.editAvailable === true && (
                    <Prompt
                        when={this.props.isDirty}
                        message={(location) => {
                            // Check if pathname is the same (then it's only a hashbang nav and no need for the question)
                            if (location.pathname === window.location.pathname) {
                                return true;
                            } else {
                                return "Du har gjort ändringar du inte har sparat, är du säker på att du vill lämna?";
                            }
                        }}
                    />
                )}
                {this.props.editAvailable === true && (
                    <>
                        <EditDesign
                            setColors={this.setColors}
                            setSiteGraphic={this.setSiteGraphic}
                            setTheme={this.setTheme}
                            setDesignTemplate={this.setDesignTemplate}
                            setFont={this.setFont}
                            site={this.props.site}
                            close={this.closeDrawer.bind(this)}
                            open={this.state.openDrawer === "design" && this.props.editMode === true}
                            portalNode={this.props.drawerSlotPortalNode}
                        />
                        <EditMore
                            site={this.props.site}
                            quickStart={this.props.quickStart}
                            close={this.closeDrawer.bind(this)}
                            open={this.state.openDrawer === "more" && this.props.editMode === true}
                            portalNode={this.props.drawerSlotPortalNode}
                        />
                    </>
                )}
                {this.props.editAvailable === true && (
                    <ActionBar primary={true}>
                        {this.props.editMode !== true ? (
                            <ActionBarItem text="Redigera" icon={IconEdit()} onClick={this.props.toggleEditMode} />
                        ) : (
                            <>
                                <ActionBarItem
                                    text="Spara"
                                    icon={IconSave()}
                                    highlight={this.props.isDirty === true}
                                    onClick={this.props.saveOrCreateSite}
                                    testId="button-actionbar-save"
                                />
                                <ActionBarItem
                                    text="Välj adress"
                                    hide={this.showPublishMenuOption() === false}
                                    to={"/redigera/" + this.props.site._id + "/valj-adress"}
                                    testId="button-actionbar-select-url"
                                    icon={IconGlobe()}
                                    highlight={true}
                                    data-umami-event="select-host-button"
                                    data-umami-event-position="action-bar"
                                />
                                <ActionBarItem
                                    text="Granska"
                                    icon={IconPreview()}
                                    onClick={this.props.toggleEditMode}
                                />
                                <ActionBarItem
                                    text="Design"
                                    active={this.state.openDrawer === "design"}
                                    icon={IconDesign()}
                                    onClick={() =>
                                        this.setState({
                                            openDrawer: this.state.openDrawer === "design" ? null : "design",
                                        })
                                    }
                                    testId={"button-actionbar-design"}
                                />
                                <ActionBarItem
                                    text="Mer"
                                    active={this.state.openDrawer === "more"}
                                    icon={IconToggleContentModuleActionBar()}
                                    onClick={() =>
                                        this.setState({
                                            openDrawer: this.state.openDrawer === "more" ? null : "more",
                                        })
                                    }
                                    testId={"button-actionbar-more"}
                                />
                            </>
                        )}
                    </ActionBar>
                )}
                {this.state.loading === true && <Loading fixed={true} />}
                <div className="home font-setter">
                    <style
                        dangerouslySetInnerHTML={{
                            __html: `
                .font-setter h1, .font-setter h2, .font-setter h3, .font-setter h4, .font-setter h5  {
                  font-family: ${this.props.site.fonts.heading};
                }
                .font-setter, .font-setter .font-text {
                  font-family: ${this.props.site.fonts.text};
                }
              `,
                        }}
                    />

                    {!(this.props.site.modules && this.props.site.modules.length) ? (
                        <div>Inga moduler att visa</div>
                    ) : (
                        <>
                            {this.state.showAddModule === true && (
                                <AddModule
                                    addNewModule={this.addNewModule.bind(this)}
                                    closeAddModule={this.closeAddModule.bind(this)}
                                    addModuleBeforeThisModule={this.state.addModuleBeforeThisModule}
                                    site={this.props.site}
                                />
                            )}
                            {this.state.showEditMenu === true && (
                                <EditMenu
                                    site={this.props.site}
                                    closeEditMenu={this.closeEditMenu.bind(this)}
                                    updateHideInMenu={this.updateHideInMenu.bind(this)}
                                    updateMenuItem={this.updateMenuItem.bind(this)}
                                />
                            )}
                            {this.state.showDeleteModuleConfirmation === true && (
                                <Confirmation
                                    confirmationTitle="Ta bort modul"
                                    confirmButtonText="Ta bort"
                                    abortButtonText="Avbryt"
                                    confirmationText="Är du säker på att du vill ta bort den här modulen? Du kan inte ångra dig och innehållet raderas permanent."
                                    confirmCallback={this.deleteModule.bind(this)}
                                    closeCallback={this.closeConfirmationCallback.bind(this)}
                                />
                            )}
                            {this.props.site.siteGraphic ? (
                                <SiteGraphic
                                    type="SITE"
                                    siteGraphic={this.props.site.siteGraphic}
                                    editAvailable={this.props.editAvailable}
                                />
                            ) : null}
                            <Menu
                                site={this.props.site}
                                showEditMenu={this.showEditMenu}
                                editMode={this.props.editMode}
                                editAvailable={this.props.editAvailable}
                            />
                            <div className={styles.modulesListWrapper}>{this.listModules()}</div>
                        </>
                    )}
                </div>
            </div>
        );
    }
}
