import { getProductPriceAsKronor, Product } from "../../../../../../utils/products";
import { ISite, ISiteV2 } from "../../../../../interfaces/Site";
import { domainOption } from "../components/SelectProduct/SelectProduct";

interface IProductFromDomainOption {
    price: number;
    information?: string;
    product: Product | null;
}

export const getProductFromDomainOption = (product: domainOption, site: ISite | ISiteV2): IProductFromDomainOption => {
    if (product === domainOption.CUSTOM_DOMAIN_OTHER_REGISTRAR) {
        if (site.customDomainRegistrationsAvailable) {
            return {
                product: null,
                price: 0,
            };
        }
        if (site.customDomainActive) {
            return {
                product: null,
                price: 0,
                information: "Du har redan registrerat en domän, kontakta oss för att byta",
            };
        }
        if (site.active) {
            return {
                product: "domain_name_other_registrar_upgrade_from_subdomain",
                price: getProductPriceAsKronor("domain_name_other_registrar_upgrade_from_subdomain"),
                information: "Uppgradera till egen domän",
            };
        }
        return {
            product: "domain_name_other_registrar",
            price: getProductPriceAsKronor("domain_name_other_registrar"),
        };
    }
    if (product === domainOption.CUSTOM_DOMAIN) {
        if (site.customDomainRegistrationsAvailable) {
            return {
                product: null,
                price: 0,
            };
        }
        if (site.customDomainActive) {
            return {
                product: null,
                price: 499,
                information: "Du har redan registrerat en domän, kontakta oss för att byta",
            };
        }
        if (site.active) {
            return {
                product: "domain_name_upgrade_from_subdomain",
                price: getProductPriceAsKronor("domain_name_upgrade_from_subdomain"),
                information: "Uppgradera till egen domän",
            };
        }
        return {
            product: "domain_name",
            price: getProductPriceAsKronor("domain_name"),
        };
    } else if (product === domainOption.SUBDOMAIN) {
        if (site.active) {
            return {
                product: null,
                price: 0,
                information: "Du har redan köpt det här",
            };
        }
        return {
            product: "subdomain",
            price: getProductPriceAsKronor("subdomain"),
        };
    } else {
        throw new Error("No product in getProductFromDomainOption");
    }
};
