import React from "react";
import styles from "./Select.module.scss";
import { Label } from "../Label/Label";

interface IProps {
    handleChange: (value: string) => void;
    value: string | undefined;
    options: {
        title: string;
        value: string;
    }[];
    placeholder?: string;
    disabled?: boolean;
    label?: string;
    defaultValue?: any;
    id: string;
}

export const Select = (props: IProps) => {
    const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        props.handleChange(event.target.value);
    };
    return (
        <>
            {props.label ? (
                <Label htmlFor={props.id} className={styles.label}>
                    {props.label}
                </Label>
            ) : null}
            <select
                className={styles.select}
                id={props.id}
                value={props.value}
                onChange={onChange}
            >
                {!props.value && props.placeholder ? <option value="">{props.placeholder}</option> : ""}
                {props.options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.title}
                    </option>
                ))}
            </select>
        </>
    );
};
