import { IRsvpV2 } from "../../ViewRsvp";
import dayjs from "dayjs";
import styles from "./RsvpAnswers.module.scss";
import { getInRsvpTranslation, getInRsvpTranslationOneLanguage } from "../../utils/getInRsvpTranslation";
import { IFormElementSummary, uniqueFormElementsFromRsvps } from "../../utils/uniqueFormElementsFromRsvps";
import { answerFromFormElementId } from "../../utils/textAnswerFromFormElementId";
import { RsvpChoiceGraphs } from "./components/RsvpChoiceGraphs/RsvpChoiceGraphs";
import { IconDelete } from "../../../../../../icons/delete";
import { useState } from "react";
import { Confirmation } from "../../../../../../modules/confirmation/Confirmation";

interface IProps {
    rsvps: IRsvpV2[];
    demo: boolean;
    deleteRsvpItem: (id: string) => void;
}

export const RsvpAnswers = (props: IProps) => {
    const [deleteItemId, setDeleteItemId] = useState<null | string>(null);

    const getAnswerStyling = (rsvp: IRsvpV2, column: IFormElementSummary) => {
        if (column.formElementType === "ShortText") return "";
        const formElement = rsvp.formElements.find((formElement) => formElement.formElement.id === column.id);

        let answer = getInRsvpTranslation(formElement?.selectedOption?.text, rsvp);

        if (!answer) return "";

        answer = answer.toUpperCase();
        if (["JA", "JA!", "JA, SJÄLVKLART!", "YES", "YES!"].indexOf(answer) > -1) {
            return styles.statusYes;
        } else if (["NEJ", "NEJ TYVÄRR", "NEJ, TYVÄRR", "NEJ, JAG KAN TYVÄRR INTE KOMMA", "NO"].indexOf(answer) > -1) {
            return styles.statusNo;
        }

        return "";
    };

    const questions = uniqueFormElementsFromRsvps(props.rsvps);

    return (
        <div className={styles.wrapper}>
            {deleteItemId ? (
                <Confirmation
                    confirmationTitle="Ta bort"
                    confirmButtonText="Ta bort"
                    abortButtonText="Avbryt"
                    confirmationText="Är du säker på att du vill ta bort det här OSA-svaret? Åtgärden går inte att ångra"
                    confirmCallback={() => props.deleteRsvpItem(deleteItemId)}
                    closeCallback={() => setDeleteItemId(null)}
                ></Confirmation>
            ) : null}
            <h1>
                OSA
                <div className={styles.totalCount}>{props.rsvps.length} svar</div>
            </h1>
            {props.demo === true ? (
                <div className={styles.intro}>
                    Du har inte fått någon OSA än. Nedan visar vi några exempelanmälningar. Du väljer själv vilka frågor
                    dina gäster ska svara på när du skapar OSA-formuläret.
                </div>
            ) : null}
            <RsvpChoiceGraphs questions={questions} rsvps={props.rsvps} />
            <div className={styles.answers}>
                <table>
                    <thead>
                        <tr>
                            {questions.map((column) => (
                                <th key={column.id}>
                                    <div className={styles.truncate}>{getInRsvpTranslationOneLanguage(column.label)}</div>
                                </th>
                            ))}
                            <th>Datum</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.rsvps.map((rsvp) => (
                            <tr key={rsvp._id}>
                                {questions.map((column) => (
                                    <td key={column.id}>
                                        <span className={getAnswerStyling(rsvp, column)}>
                                            {answerFromFormElementId(rsvp, column.id)}
                                        </span>
                                    </td>
                                ))}
                                <td className={styles.dateColumn}>{dayjs(rsvp.createdAt).format("YYYY-MM-DD")}</td>
                                <td>
                                    <button onClick={() => setDeleteItemId(rsvp._id)} className={styles.delete}>
                                        {IconDelete()}
                                        <span>Ta bort</span>
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};
