import React from "react";
import Button from "../../../../../../components/button/Button";
import Axios from "axios";
import Loading from "../../../../../../components/Loading/Loading";
import { ISite, ISiteV2 } from "../../../../../../interfaces/Site";
import { getBrandedDomainName } from "../../../../../../utils/getBrand";
import { slugify } from "../../../../../../utils/Slug";
import styles from "./EditSubdomain.module.scss";
import { SubdomainPayment } from "./components/SubdomainPayment/SubdomainPayment";
import { domainOption } from "../SelectProduct/SelectProduct";
import { getProductFromDomainOption } from "../../utils/getProductFromDomainOption";
import { trackEvent } from "../../../../../../../utils/UmamiHelpers";

interface IProps {
    site: ISite | ISiteV2;
    updateHost: (host: string) => void;
    showError: any;
}

interface IState {
    subdomain: string;
    loading: boolean;
    isAvailable: null | boolean;
    showPayment: boolean;
}

const getInitialSubdomainFromHost = (host: string): string => (host ? host.split(".")[0] : "");

export class EditSubdomain extends React.Component<IProps> {
    state: IState = {
        subdomain: getInitialSubdomainFromHost(this.props.site.host),
        loading: false,
        isAvailable: null,
        showPayment: false,
    };

    hostFromSubdomain = (subdomain: string) => {
        return subdomain + "." + getBrandedDomainName();
    };

    isAvailable = async (host: string): Promise<void> => {
        this.setState({ loading: true });
        try {
            const res = await Axios.get(
                process.env.REACT_APP_API_URL +
                    "/api/sites/" +
                    this.props.site._id +
                    "/host/subdomain-available/" +
                    host
            );
            this.setState({ loading: false, isAvailable: res.data.available });
        } catch (err) {
            this.setState({ loading: false });
            this.props.showError("Misslyckades att kontrollera tillgänglighet av adress", (err as any).response);
        }
    };

    handlePaymentDone = async (host: string, paymentReference: string): Promise<void> => {
        trackEvent("site-payment-done", {product: "subdomain"});

        await this.props.updateHost(host);
        window.location.href =
            "/redigera/" + this.props.site._id + "/valj-adress/subdoman/bekraftelse/" + paymentReference;
    };

    nameInput: HTMLInputElement | null | undefined;

    handleTextChange(event: React.ChangeEvent<HTMLInputElement>) {
        let newValue = slugify(event.target.value);
        this.setState({
            subdomain: newValue,
            isAvailable: null,
        });
    }

    render() {
        const subdomainProduct = getProductFromDomainOption(domainOption.SUBDOMAIN, this.props.site);
        return (
            <div className={styles.wrapper}>
                {this.state.showPayment ? (
                    <SubdomainPayment
                        handlePaymentDone={(paymentReference: string) =>
                            this.handlePaymentDone(this.hostFromSubdomain(this.state.subdomain), paymentReference)
                        }
                        host={this.hostFromSubdomain(this.state.subdomain)}
                        site={this.props.site}
                        close={() => this.setState({ showPayment: false })}
                    />
                ) : null}
                <h1>Sök ledig adress</h1>
                <div className={styles.hostWrapper}>
                    {this.state.loading === true && <Loading fixed={true}></Loading>}
                    <div className={styles.inputAndButtonWrapper}>
                        <div>
                            <label htmlFor="subdomain">Adress till din sida</label>

                            <div className={styles.inputWrapper}>
                                <input
                                    className={styles.input}
                                    type="text"
                                    onChange={this.handleTextChange.bind(this)}
                                    id="subdomain"
                                    name="subdomain"
                                    value={this.state.subdomain}
                                    data-test-id="search-for-available-subdomain-input"
                                    ref={(input) => {
                                        this.nameInput = input;
                                    }}
                                />
                                <div className={styles.host} onClick={() => this.nameInput?.focus()}>
                                    .{getBrandedDomainName()}
                                </div>
                            </div>
                        </div>
                        <div>
                            {this.props.site.host === this.hostFromSubdomain(this.state.subdomain) ||
                            this.state.subdomain === "" ? (
                                ""
                            ) : (
                                <Button
                                    data-test-id="button-search-for-subdomain"
                                    buttonText="Kontrollera tillgänglighet"
                                    type="primary"
                                    callback={() => this.isAvailable(this.hostFromSubdomain(this.state.subdomain))}
                                    fullWidth={true}
                                />
                            )}
                        </div>
                    </div>
                </div>

                {this.state.isAvailable === false ? (
                    <div className={styles.infoBox}>
                        <h3 className={styles.notAvailableTitle}>
                            {this.hostFromSubdomain(this.state.subdomain)} är tyvärr upptagen
                        </h3>
                        Adressen {this.hostFromSubdomain(this.state.subdomain)} är tyvärr redan upptagen. Prova att söka
                        efter en ny adress.
                    </div>
                ) : null}

                {this.state.isAvailable === true ? (
                    <div className={styles.infoBox}>
                        <h3 className={styles.availableTitle}>
                            {this.hostFromSubdomain(this.state.subdomain)} är tillgänglig!
                        </h3>
                        Adressen {this.hostFromSubdomain(this.state.subdomain)} är tillgänglig. Tryck nedan för att
                        välja adressen.{" "}
                        {subdomainProduct.price > 0 ? (
                            <span>
                                <strong>
                                    Efter att du har köpt adressen kan du ändra till en annan adress utan kostnad om du
                                    ångrar dig.
                                </strong>{" "}
                                Du kan självklart fortsätta att redigera sidan precis som innan när du köpt adressen.
                            </span>
                        ) : null}
                        <div className={styles.buttonWrapper}>
                            {subdomainProduct.price > 0 ? (
                                <Button
                                    type="primary"
                                    data-test-id="buy-subdomain-button"
                                    callback={() => {
                                        this.setState({ showPayment: true });
                                    }}
                                    buttonText={"Köp " + this.hostFromSubdomain(this.state.subdomain)}
                                />
                            ) : (
                                <Button
                                    type="primary"
                                    callback={async () => {
                                        await this.props.updateHost(this.hostFromSubdomain(this.state.subdomain));
                                        window.location.href =
                                            "/redigera/" + this.props.site._id + "/valj-adress/subdoman/bekraftelse/";
                                    }}
                                    buttonText={"Byt adress till " + this.hostFromSubdomain(this.state.subdomain)}
                                />
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}
