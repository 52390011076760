import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { Prompt } from "react-router";
import { IconDesign } from "../../icons/design";
import { IconEdit } from "../../icons/edit";
import { IconPreview } from "../../icons/preview";
import { IconSave } from "../../icons/save";
import { enumTheme, IColorScheme, ILanguage, ISiteV2 } from "../../interfaces/Site";
import { ActionBar } from "../../modules/actionBar/ActionBar";
import { ActionBarItem } from "../../modules/actionBar/components/ActionBarItem/ActionBarItem";
import { EditDesign } from "../../modules/EditDesign/EditDesign";
import "../../style/variables.scss";
import { getBrand } from "../../utils/getBrand";
import { SiteGraphic, TSiteGraphic } from "../../components/SiteGraphic/SiteGraphic";
import { IDesignTemplate } from "../../modules/EditDesignTemplate/utils/designTemplates";
import { IconToggleContentModuleActionBar } from "../../icons/toggleContentModuleActionBar";
import { IconGlobe } from "../../icons/globe";
import { TFontHeading, TFontText } from "../../enums/font";
import { SiteModulesListEdit } from "../../siteModules/SiteModulesListEdit";
import { useImmer } from "use-immer";
import {
    setGeneratorColorStylesVariables,
    setGeneratorFontsStylesVariables,
} from "../../utils/SetGeneratorStylesVariables";
import { loadSiteFonts } from "../../utils/LoadSiteFonts";
import { SiteModulesList } from "../../siteModules/SiteModulesList";
import { Menu } from "../../modules/Menu/Menu";
import { Dialog } from "../../editComponents/Dialog/Dialog";
import { MenuEdit } from "../../components/MenuEdit/MenuEdit";
import { SelectLanguageEdit } from "../../components/SelectLanguage/SelectLanguageEdit";
import { EditMoreV2 } from "./components/EditMoreV2/EditMoreV2";
import { SiteModulesListWrapper } from "./components/SiteModulesListWrapper/SiteModulesListWrapper";
import { SiteFontSetter } from "./components/SiteFontSetter/SiteFontSetter";
import { ThemeStylesSetter } from "./components/ThemeStylesSetter/ThemeStylesSetter";

interface IProps {
    site: ISiteV2;
    quickStart: boolean;
    routerHistory: any;
    drawerSlotPortalNode?: JSX.Element;
    saveOrCreateSite: (site: ISiteV2) => void;
    showHelpFn: () => void;
    showHelp: boolean;
    ignoreWarningNotSaved: boolean;
}

export const GeneratorSiteEdit = (props: IProps) => {
    const [openDrawer, setOpenDrawer] = useState<"more" | "design" | null>(null);
    const [showEditMenu, setShowEditMenu] = useState(false);
    const [editMode, setEditMode] = useState(true);

    const [site, setSite] = useImmer<ISiteV2>(props.site);

    useEffect(() => {
        setSite(props.site);
    }, [props.site, setSite]);

    const isDirty = site !== props.site;

    useEffect(() => {
        const onBeforeUnload = (e: BeforeUnloadEvent) => {
            e.preventDefault();
            e.returnValue = "";
            delete e["returnValue"];
        };
        if (isDirty) {
            window.addEventListener("beforeunload", onBeforeUnload);

            return () => {
                window.removeEventListener("beforeunload", onBeforeUnload);
            };
        }
    }, [isDirty]);

    const setColors = (colorScheme: IColorScheme) => {
        setSite((draft) => {
            draft.colorScheme = colorScheme;
        });
        setGeneratorColorStylesVariables(colorScheme);
    };

    const setFont = (font: TFontHeading | TFontText, fontType: "heading" | "text") => {
        setSite((draft) => {
            if (fontType === "heading") {
                draft.fonts.heading = font as TFontHeading;
            } else if (fontType === "text") {
                draft.fonts.text = font as TFontText;
            }

            setGeneratorFontsStylesVariables(draft.fonts);
        });
    };

    const setTheme = (theme: enumTheme) => {
        setSite((draft) => {
            draft.theme = theme;
        });
    };

    const setSiteGraphic = (siteGraphic: TSiteGraphic | null) => {
        setSite((draft) => {
            draft.siteGraphic = siteGraphic;
        });
    };

    const setAvailableLanguages = (languages: ILanguage[]) => {
        setSite((draft) => {
            draft.availableLanguages = languages;
        });
    };

    const setDesignTemplate = (designTemplate: IDesignTemplate) => {
        setSite((draft) => {
            draft.theme = designTemplate.theme;
            draft.fonts.heading = designTemplate.fonts.heading;
            draft.fonts.text = designTemplate.fonts.text;
            setGeneratorFontsStylesVariables(designTemplate.fonts);
            loadSiteFonts(designTemplate.fonts);
            draft.siteGraphic = designTemplate.siteGraphic;
            draft.colorScheme = designTemplate.colorScheme;
            setGeneratorColorStylesVariables(designTemplate.colorScheme);
            draft.mostRecentSelectedDesignTemplate = designTemplate.name;
        });
    };

    const showPublishMenuOption = () => props.quickStart !== true && site.active !== true;

    const setAndReturnTitle = () => {
        let title;
        if (props.quickStart) {
            title = "Prova gratis utan registrering - " + getBrand();
        } else {
            title = "Redigera sida - " + getBrand();
        }
        window.document.title = title;
        return title;
    };

    return (
        <>
            <Helmet>
                <title>{setAndReturnTitle()}</title>
            </Helmet>
            <ThemeStylesSetter site={site}>
                <div id="DialogPublicPlacement" />
                <Prompt
                    when={isDirty && !props.ignoreWarningNotSaved}
                    message={(location) => {
                        // Check if pathname is the same (then it's only a hashbang nav and no need for the question)
                        if (location.pathname === window.location.pathname) {
                            return true;
                        } else {
                            return "Du har gjort ändringar du inte har sparat, är du säker på att du vill lämna?";
                        }
                    }}
                />
                <>
                    <EditDesign
                        setColors={setColors}
                        setSiteGraphic={setSiteGraphic}
                        setTheme={setTheme}
                        setDesignTemplate={setDesignTemplate}
                        setFont={setFont}
                        site={site}
                        close={() => setOpenDrawer(null)}
                        open={openDrawer === "design" && editMode === true}
                        portalNode={props.drawerSlotPortalNode}
                    />
                    <EditMoreV2
                        site={site}
                        quickStart={props.quickStart}
                        close={() => setOpenDrawer(null)}
                        open={openDrawer === "more" && editMode === true}
                        portalNode={props.drawerSlotPortalNode}
                        setAvailableLanguages={setAvailableLanguages}
                    />
                </>
                <ActionBar primary={true}>
                    {editMode !== true ? (
                        <ActionBarItem text="Redigera" icon={IconEdit()} onClick={() => setEditMode(true)} />
                    ) : (
                        <>
                            <ActionBarItem
                                text="Spara"
                                icon={IconSave()}
                                highlight={isDirty === true}
                                onClick={() => props.saveOrCreateSite(site)}
                                testId="button-actionbar-save"
                            />
                            <ActionBarItem
                                text="Välj adress"
                                hide={showPublishMenuOption() === false}
                                to={"/redigera/" + site._id + "/valj-adress"}
                                testId="button-actionbar-select-url"
                                icon={IconGlobe()}
                                highlight={true}
                                data-umami-event="select-host-button"
                                data-umami-event-position="action-bar"
                            />
                            <ActionBarItem text="Granska" icon={IconPreview()} onClick={() => setEditMode(false)} />
                            <ActionBarItem
                                text="Design"
                                active={openDrawer === "design"}
                                icon={IconDesign()}
                                onClick={() => setOpenDrawer(openDrawer === "design" ? null : "design")}
                                testId={"button-actionbar-design"}
                            />
                            <ActionBarItem
                                text="Mer"
                                active={openDrawer === "more"}
                                icon={IconToggleContentModuleActionBar()}
                                onClick={() => setOpenDrawer(openDrawer === "more" ? null : "more")}
                                testId={"button-actionbar-more"}
                            />
                        </>
                    )}
                </ActionBar>
                <Dialog
                    open={showEditMenu}
                    handleClose={() => setShowEditMenu(false)}
                    closableByOutsideClickAndEsc={true}
                >
                    <MenuEdit site={site} updateSite={setSite} close={() => setShowEditMenu(false)} />
                </Dialog>
                <SiteFontSetter>
                    {site.siteGraphic ? (
                        <SiteGraphic type="SITE" siteGraphic={site.siteGraphic} editAvailable={true} />
                    ) : null}
                    <Menu
                        site={site}
                        showEditMenu={() => setShowEditMenu(true)}
                        editMode={editMode}
                        editAvailable={true}
                    />

                    <SelectLanguageEdit site={site} />

                    <SiteModulesListWrapper site={site}>
                        {editMode ? (
                            <SiteModulesListEdit site={site} updateSite={setSite} />
                        ) : (
                            <SiteModulesList editAvailable={true} site={site} />
                        )}
                    </SiteModulesListWrapper>
                </SiteFontSetter>
            </ThemeStylesSetter>
        </>
    );
};
