import { useState } from "react";
import Button from "../../components/button/Button";
import { ILanguage, ISiteV2 } from "../../interfaces/Site";
import styles from "./EditLanguages.module.scss";
import { languageFromLanguageCode } from "../../../utils/LanguageHelpers";
import { Select } from "../../editComponents/form/Select/Select";
import { TLanguageCode } from "../../interfaces/IContentData";
import { IconDelete } from "../../icons/delete";
import { LANGUAGE_CODES } from "../../../constants/languageCodes";
import { capitalize } from "../../../utils/capitalize";
import { Switch } from "../../editComponents/form/Switch/Switch";
import { useTranslation } from "../../../contexts/LanguageContext";

interface IProps {
    site: ISiteV2;
    setAvailableLanguages: (languages: ILanguage[]) => void;
}

export const EditLanguages = (props: IProps) => {
    const [languageToAdd, setLanguageToAdd] = useState<TLanguageCode | undefined>(undefined);
    const availableLanguages: ILanguage[] = props.site.availableLanguages;
    const { translateMode, setTranslateMode } = useTranslation();

    const removeLanguage = (languageCode: TLanguageCode) => {
        const languages = availableLanguages.filter((language) => language.languageCode !== languageCode);
        props.setAvailableLanguages(languages);
    };

    const addLanguage = (languageToAdd: TLanguageCode | string) => {
        const fixedLanguageToAdd = languageToAdd.replace("____AVOID_DUPLICATE_KEY_QUICK_FIX", "") as TLanguageCode;
        const languages = [...availableLanguages];
        languages.push({ languageCode: fixedLanguageToAdd });
        props.setAvailableLanguages(languages);
        setLanguageToAdd(undefined);
    };

    const selectableLanguageCodes = LANGUAGE_CODES.filter(
        (option) => !availableLanguages.find((language) => language.languageCode === option)
    );

    const allLanguagesOptions = selectableLanguageCodes
        .map((languageCode) => ({
            title: languageFromLanguageCode(languageCode),
            value: languageCode,
        }))
        .filter((option) => option.title !== option.value);

    const topLanguageCode: TLanguageCode[] = ["sv", "en", "no", "fi", "da"].filter(
        (option) => !availableLanguages.find((language) => language.languageCode === option)
    ) as TLanguageCode[];

    const topLanguageOptions = topLanguageCode
        .map((languageCode) => ({
            title: languageFromLanguageCode(languageCode),
            value: languageCode + "____AVOID_DUPLICATE_KEY_QUICK_FIX",
        }))
        .filter((option) => option.title !== option.value);

    const languageOptions = [...topLanguageOptions, { title: "", value: "" }, ...allLanguagesOptions].map((option) => ({
        title: capitalize(option.title) as string,
        value: option.value,
    }));

    return (
        <>
            <ul className={styles.languageList}>
                {availableLanguages.map((language) => (
                    <li key={language.languageCode}>
                        <div className={styles.languageTitle}>{languageFromLanguageCode(language.languageCode)}</div>
                        <div>
                            {availableLanguages.length > 1 ? (
                                <button
                                    onClick={() => {
                                        removeLanguage(language.languageCode);
                                    }}
                                    className={styles.delete}
                                >
                                    {IconDelete()}
                                </button>
                            ) : null}
                        </div>
                    </li>
                ))}
            </ul>
            <div className={styles.addNewLanguageWrapper}>
                <div className={styles.selectWrapper}>
                    <Select
                        id="selectLanguageToAdd"
                        label="Lägg till språk"
                        value={languageToAdd}
                        placeholder="Välj språk"
                        options={languageOptions}
                        handleChange={(value) => setLanguageToAdd(value as TLanguageCode)}
                    />
                </div>
                {languageToAdd ? (
                    <div>
                        <Button buttonText={"Lägg till"} type="primary" callback={() => addLanguage(languageToAdd)} />
                    </div>
                ) : null}
            </div>
            {availableLanguages.length > 1 ? (
                <div className={styles.translateModeWrapper}>
                    <Switch
                        label="Översättningsläge"
                        name="translateMode"
                        handleChange={setTranslateMode}
                        value={translateMode}
                        id="translateMode"
                    />
                </div>
            ) : null}
        </>
    );
};
