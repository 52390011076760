import React from "react";
import styles from "./Dialog.module.scss";
import * as RadixDialog from "@radix-ui/react-dialog";
import { IconClose } from "../../icons/close";

interface IProps {
    children: JSX.Element | JSX.Element[];
    open: boolean;
    handleClose: () => void;
    closableByOutsideClickAndEsc: boolean;
    small?: boolean;
    closeButton?: boolean;
}

export const Dialog = (props: IProps) => {
    const onOpenChange = (open: boolean) => {
        if (!open) {
            props.handleClose();
        }
    };

    return (
        <RadixDialog.Root open={props.open} onOpenChange={onOpenChange}>
            <RadixDialog.Portal>
                <RadixDialog.Overlay className={styles.Overlay}>
                    <RadixDialog.Content
                        className={`${styles.content} ${props.small ? styles.small : ""}`}
                        onPointerDownOutside={(e) => {
                            if (!props.closableByOutsideClickAndEsc) e.preventDefault();
                        }}
                        onEscapeKeyDown={(e) => {
                            if (!props.closableByOutsideClickAndEsc) e.preventDefault();
                        }}
                        onInteractOutside={(e) => {
                            if (!props.closableByOutsideClickAndEsc) e.preventDefault();
                        }}
                    >
                        {props.closeButton ? (
                            <button className={styles.close} onClick={props.handleClose}>
                                <IconClose />
                            </button>
                        ) : null}
                        {props.children}
                    </RadixDialog.Content>
                </RadixDialog.Overlay>
            </RadixDialog.Portal>
        </RadixDialog.Root>
    );
};
