import Axios from "axios";
import React from "react";
import Loading from "../../../../../../components/Loading/Loading";
import IconEnvelope from "../../../../../../icons/envelope";
import IconQuestion from "../../../../../../icons/question";
import { IRvspPerson } from "../../../../../../interfaces/Rvsp";
import { ActionBar } from "../../../../../../modules/actionBar/ActionBar";
import { ActionBarItem } from "../../../../../../modules/actionBar/components/ActionBarItem/ActionBarItem";
import Confirmation from "../../../../../../modules/confirmation/Confirmation";
import { getCustomValuesFromRvsps, getDeprecatedValuesFromRsvps } from "../../../../../../utils/Rvsp";
import { RsvpAnswers } from "./components/RsvpAnswers/RsvpAnswers";
import { RsvpChoiceGraphs } from "./components/RsvpChoiceGraphs/RsvpChoiceGraphs";
import { RsvpQuestions } from "./components/RsvpQuestions/RsvpQuestions";
import styles from "./ViewRvsp.module.scss";
import writeXlsxFile from "write-excel-file";
import { getRsvpsForExport } from "./utils/getRsvpsForExport";
import { IconExport } from "../../../../../../icons/export";
import { rvspUglyCustomerFix } from "./utils/rvspUglyCustomerFix";
import { getDemoRsvps } from "./utils/getDemoRsvps";
import { trimValuesAndLabels } from "./utils/trimValuesAndLabels";

interface IProps {
    siteId?: string;
    showError: any;
    quickStart: boolean;
}

export interface ICustomValue {
    id: string;
    label: string;
    type: string;
}

export interface IDeprecatedValueObject {
    key: deprecatedRsvpValues;
    label: string;
}

export enum deprecatedRsvpValues {
    FIRSTNAME = "firstName",
    LASTNAME = "lastName",
    PHONE = "phone",
    MAIL = "mail",
}

enum View {
    answers,
    questions,
}

interface IState {
    rvsps?: IRvspPerson[];
    loading: boolean;
    customValues?: ICustomValue[];
    showDeleteConfirmation: boolean;
    deleteId: string | undefined;
    deleteConfirmationName: string | undefined;
    showEmailExport: boolean;
    deprecatedValues: IDeprecatedValueObject[] | null;
    view: View;
    demo: boolean;
}

export class ViewRvspLegacy extends React.Component<IProps, IState> {
    state: IState = {
        rvsps: undefined,
        loading: true,
        customValues: undefined,
        showDeleteConfirmation: false,
        deleteId: undefined,
        deleteConfirmationName: undefined,
        showEmailExport: false,
        deprecatedValues: null,
        view: View.answers,
        demo: false,
    };

    componentDidMount() {
        if (!this.props.quickStart && this.props.siteId) {
            this.getDataFromDb(this.props.siteId);
        } else {
            this.setDemoData();
        }
    }

    exportToExcel = async () => {
        const data = getRsvpsForExport(
            this.state.rvsps!,
            this.state.customValues,
            this.state.deprecatedValues!,
            this.getCustomValueWithLabel
        );
        await writeXlsxFile(data, {
            fileName: "osa.xlsx",
        });
    };

    getDataFromDb = (siteId: string) => {
        Axios.get(process.env.REACT_APP_API_URL + "/api/rsvps/" + siteId)
            .then((res) => {
                let rvsps = res.data.data;
                if (this.props.siteId === "635590baf6931b4f16073636") {
                    rvsps = rvspUglyCustomerFix(rvsps);
                }
                trimValuesAndLabels(rvsps);
                if (!rvsps || !rvsps.length) {
                    this.setDemoData();
                } else {
                    const customValues = getCustomValuesFromRvsps(rvsps);
                    const deprecatedValues = getDeprecatedValuesFromRsvps(rvsps);
                    this.setState({
                        rvsps: rvsps,
                        loading: false,
                        customValues,
                        deprecatedValues,
                    });
                }
            })
            .catch((err) => {
                this.props.showError("Misslyckades att ladda OSA-svar", err.response);
            });
    };

    setDemoData = () => {
        const rsvps = getDemoRsvps();
        const customValues = getCustomValuesFromRvsps(rsvps);
        this.setState({
            rvsps: rsvps,
            loading: false,
            customValues,
            demo: true,
            deprecatedValues: [],
        });
    };

    getCustomValueWithLabel = (rvsp: IRvspPerson, label: string): string => {
        const filtered = rvsp.customValues!.filter((customValue) => {
            return customValue.label === label;
        });
        if (filtered.length === 0) {
            return "";
        } else if (filtered.length === 1) {
            return filtered[0].value!;
        } else {
            // This should not happen if not strange user case, but if there are multiple fields with same label, show them togheter.
            return filtered.map((item) => item.value).join(", ");
        }
    };

    deleteRvsp = () => {
        const deleteId = this.state.deleteId;
        if (this.state.demo === true) {
            return;
        }
        this.setState({
            loading: true,
            deleteId: undefined,
            deleteConfirmationName: undefined,
        });
        Axios.delete(process.env.REACT_APP_API_URL + "/api/rsvps/" + this.props.siteId + "/" + deleteId)
            .then((response) => {
                this.getDataFromDb(this.props.siteId!);
            })
            .catch((err) => {
                this.setState({ loading: false });
                this.props.showError("Misslyckades att ta bort OSA");
            });
    };

    showDeleteConfirmation = (id: string, name: string) => {
        this.setState({
            showDeleteConfirmation: true,
            deleteConfirmationName: name,
            deleteId: id,
        });
    };

    closeConfirmationCallback = () => {
        this.setState({
            showDeleteConfirmation: false,
            deleteConfirmationName: undefined,
            deleteId: undefined,
        });
    };

    setView = (view: View): void => {
        this.setState({
            view,
        });
    };

    deleteConfirmationText = (name: string) => {
        if (name) {
            return (
                "Är du säker på att du vill ta bort anmälan från " +
                name +
                "? Åtgärden går inte att ångra och innehållet försvinner permanent."
            );
        } else {
            return "Är du säker på att du vill ta bort den här anmälan? Åtgärden går inte att ångra och innehållet försvinner permanent.";
        }
    };

    render() {
        return (
            <>
                {this.state.showDeleteConfirmation === true && (
                    <Confirmation
                        confirmationTitle="Ta bort"
                        confirmButtonText="Ta bort"
                        abortButtonText="Avbryt"
                        confirmationText={this.deleteConfirmationText(this.state.deleteConfirmationName!)}
                        confirmCallback={this.deleteRvsp.bind(this)}
                        closeCallback={this.closeConfirmationCallback.bind(this)}
                    ></Confirmation>
                )}
                <ActionBar primary={true}>
                    <ActionBarItem
                        text="Svar"
                        icon={IconEnvelope()}
                        active={this.state.view === View.answers}
                        onClick={() => this.setView(View.answers)}
                    />
                    <ActionBarItem
                        text="Frågor"
                        icon={IconQuestion()}
                        active={this.state.view === View.questions}
                        onClick={() => this.setView(View.questions)}
                    />
                    <ActionBarItem
                        text="Exportera till excel"
                        icon={IconExport()}
                        active={false}
                        onClick={this.exportToExcel}
                    />
                </ActionBar>
                <div className={styles.wrapper}>
                    <h1>
                        OSA
                        {this.state.demo !== true && this.state.rvsps && this.state.rvsps.length > 0 ? (
                            <div className={styles.totalCount}>{this.state.rvsps?.length} svar</div>
                        ) : null}
                    </h1>

                    {this.state.loading === true ? (
                        <Loading inverted={true} block={true}></Loading>
                    ) : (
                        <>
                            {this.state.demo === true ? (
                                <div className={styles.intro}>
                                    Du har inte fått någon OSA än. Nedan visar vi några exempelanmälningar. Du väljer
                                    själv vilka frågor dina gäster ska svara på när du skapar OSA-formuläret.
                                </div>
                            ) : null}
                            <>
                                <div>
                                    {this.state.view === View.questions ? (
                                        <RsvpQuestions
                                            rsvps={this.state.rvsps!}
                                            customValues={this.state.customValues!}
                                            deprecatedValues={this.state.deprecatedValues!}
                                            getCustomValueWithLabel={this.getCustomValueWithLabel.bind(this)}
                                        />
                                    ) : null}
                                    {this.state.view === View.answers ? (
                                        <>
                                            <div>
                                                <RsvpChoiceGraphs
                                                    rsvps={this.state.rvsps!}
                                                    customValues={this.state.customValues!}
                                                    deprecatedValues={this.state.deprecatedValues!}
                                                    getCustomValueWithLabel={this.getCustomValueWithLabel.bind(this)}
                                                />
                                            </div>
                                            <RsvpAnswers
                                                rsvps={this.state.rvsps!}
                                                customValues={this.state.customValues}
                                                getCustomValueWithLabel={this.getCustomValueWithLabel.bind(this)}
                                                showDeleteConfirmation={this.showDeleteConfirmation.bind(this)}
                                                deprecatedValues={this.state.deprecatedValues!}
                                            />
                                        </>
                                    ) : null}
                                </div>
                            </>
                        </>
                    )}
                </div>
            </>
        );
    }
}
