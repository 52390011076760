import React from "react";
import { Radio } from "./components/Radio/Radio";
import styles from "./RadioGroup.module.scss";
import { IFormElementOption } from "../../../../../../interfaces/FormElement";
import { ISiteV2 } from "../../../../../../interfaces/Site";
import { useTranslation } from "../../../../../../../contexts/LanguageContext";

interface IProps {
    value?: IFormElementOption;
    name: string;
    id: string;
    label: string;
    site: ISiteV2;
    options: IFormElementOption[];
    handleChange: (option: IFormElementOption) => void;
}

export const RadioGroup = (props: IProps) => {
    const { getInTranslation } = useTranslation();

    return (
        <div className={`${styles[props.site.theme]}`}>
            <div className={styles.label}>
                {props.label}
                <div className="invalidFeedback" id={props.id + "_invalid_feedback"} data-invalid-feedback="true"></div>
            </div>
            <div className={styles.options}>
                {props.options?.map((option) => (
                    <div className={styles.option} key={option.id}>
                        <Radio
                            value={option}
                            handleChange={props.handleChange}
                            label={getInTranslation(option.text)}
                            id={option.id + option.id}
                            name={props.id}
                            site={props.site}
                            checked={props.value?.id === option.id}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};
