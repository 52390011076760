import React, { useState } from "react";
import styles from "./EditPrintDesign.module.scss";
import { IPrintableCard, TPrintableCardLayout } from "../../interfaces/IPrintableCard";
import { IColorScheme, ISite, ISiteV2 } from "../../../../../../../../../../interfaces/Site";
import { TSiteGraphic } from "../../../../../../../../../../components/SiteGraphic/SiteGraphic";
import { Drawer } from "../../../../../../../../../../components/Drawer/Drawer";
import { ActionBar } from "../../../../../../../../../../modules/actionBar/ActionBar";
import { ActionBarItem } from "../../../../../../../../../../modules/actionBar/components/ActionBarItem/ActionBarItem";
import { IconGraphic } from "../../../../../../../../../../../landing/icons/graphic";
import { IconFont } from "../../../../../../../../../../icons/font";
import { IconColor } from "../../../../../../../../../../icons/colors";
import { EditSiteGraphic } from "../../../../../../../../../../modules/EditSiteGraphic/EditSiteGraphic";
import { EditFont } from "../../../../../../../../../../modules/EditFont/EditFont";
import { EditColorV2 } from "../../../../../../../../../../modules/EditColorV2/EditColorV2";
import { Updater } from "use-immer";
import Button from "../../../../../../../../../../components/button/Button";
import isEqual from "lodash.isequal";
import { TFontHeading, TFontText } from "../../../../../../../../../../enums/font";
import { SelectPrintLayout } from "./components/SelectPrintLayout/SelectPrintLayout";
import { IconLayout } from "../../../../../../../../../../icons/layout";
import { createDemoCard } from "../../../../utils/createDemoCard";

interface IProps {
    print: IPrintableCard;
    site: ISite | ISiteV2;
    updateCard: Updater<IPrintableCard>;
    close: () => void;
    open: boolean;
    portalNode: any;
}

enum View {
    FONTS = "FONTS",
    COLORS = "COLORS",
    SITE_GRAPHICS = "SITE_GRAPHICS",
    PRINT_LAYOUT = "PRINT_LAYOUT"
}


export function EditPrintDesign(props: IProps) {
    const [currentView, setCurrentView] = useState(View.PRINT_LAYOUT);

    const setFont = (font: TFontHeading | TFontText, fontType: "heading" | "text") => {
        props.updateCard((card) => {
            if (fontType === "heading") {
                card.fonts.heading = font as TFontHeading;
            } else if (fontType === "text") {
                card.fonts.text = font as TFontText;
            }
        });
    };

    const setColors = (colors: IColorScheme) => {
        props.updateCard((card) => {
            card.colorScheme = colors;
        });
    };

    const setSiteGraphic = (siteGraphic: TSiteGraphic | null) => {
        props.updateCard((card) => {
            card.siteGraphic = siteGraphic;
        });
    };

    const setPrintLayout = (layout: TPrintableCardLayout) => {
        props.updateCard((card) => {
            card.content = createDemoCard(layout, props.site, props.print).content;
            card.layout = layout;
        });
    };

    return (
        <Drawer
            title="Design"
            close={props.close}
            open={props.open}
            portalNode={props.portalNode}
            resetScrollOnNewKey={currentView}
            drawerId="editPrintDesign"
            actionBar={
                <ActionBar multipleRows={false}>
                    <ActionBarItem
                        text="Layout"
                        icon={IconLayout()}
                        active={currentView === View.PRINT_LAYOUT}
                        onClick={() => setCurrentView(View.PRINT_LAYOUT)}
                        testId="editPrintDesignActionBarItemPrintLayout"
                    />
                    <ActionBarItem
                        text="Grafik"
                        icon={IconGraphic()}
                        active={currentView === View.SITE_GRAPHICS}
                        onClick={() => setCurrentView(View.SITE_GRAPHICS)}
                        testId="editPrintDesignActionBarItemSiteGraphic"
                    />
                    <ActionBarItem
                        text="Typsnitt"
                        icon={IconFont()}
                        active={currentView === View.FONTS}
                        onClick={() => setCurrentView(View.FONTS)}
                        testId="editPrintDesignActionBarItemFonts"
                    />
                    <ActionBarItem
                        text="Färger"
                        icon={IconColor()}
                        active={currentView === View.COLORS}
                        onClick={() => setCurrentView(View.COLORS)}
                        testId="editPrintDesignActionBarItemColors"
                    />
                </ActionBar>
            }
        >
            <div>
                {currentView === View.PRINT_LAYOUT && (
                    <>
                        <SelectPrintLayout site={props.site} setPrintLayout={setPrintLayout} print={props.print} />
                    </>
                )}
                {currentView === View.SITE_GRAPHICS && (
                    <>
                        {props.print.siteGraphic !== props.site.siteGraphic ? (
                            <div className={styles.sameAsSiteWrapper}>
                                <Button
                                    callback={() => setSiteGraphic(props.site.siteGraphic || null)}
                                    type="primary"
                                    buttonText="Matcha med bröllopssida"
                                />
                            </div>
                        ) : null}
                        <EditSiteGraphic setSiteGraphic={setSiteGraphic} siteGraphic={props.print.siteGraphic} />
                    </>
                )}
                {currentView === View.FONTS && (
                    <>
                        {!isEqual(props.site.fonts, props.print.fonts) ? (
                            <div className={styles.sameAsSiteWrapper}>
                                <Button
                                    callback={() => {
                                        setFont(props.site.fonts.heading, "heading");
                                        setFont(props.site.fonts.text, "text");
                                    }}
                                    type="primary"
                                    buttonText="Matcha med bröllopssida"
                                />
                            </div>
                        ) : null}
                        <EditFont setFont={setFont} fonts={props.print.fonts} />
                    </>
                )}
                {currentView === View.COLORS && (
                    <>
                        {!isEqual(props.site.colorScheme, props.print.colorScheme) ? (
                            <div className={styles.sameAsSiteWrapper}>
                                <Button
                                    callback={() => setColors(props.site.colorScheme!)}
                                    type="primary"
                                    buttonText="Matcha med bröllopssida"
                                />
                            </div>
                        ) : null}
                        <EditColorV2 setColors={setColors} currentColors={props.print.colorScheme} />
                    </>
                )}
            </div>
        </Drawer>
    );
}
