import React from "react";
import styles from "./Input.module.scss";
import { Label } from "../Label/Label";

interface IProps {
    value: string;
    handleChange: (value: string) => void;
    label?: string | JSX.Element;
    placeholder?: string;
    type?: "text" | "password" | "email" | "date" | "number" | "search" | "datetime-local";
    "data-test-id"?: string;
    id: string;
    autoFocus?: boolean;
    name: string;
    disabled?: boolean;
    readOnly?: boolean;
    className?: string;
    required?: boolean;
    min?: string;
    max?: string;
}

export const Input = (props: IProps) => {
    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        props.handleChange(event.target.value);
    };
    return (
        <div className={`${styles.wrapper} ${props.className}`}>
            {props.label ? (
                <Label htmlFor={props.id} className={styles.label}>
                    {props.label}
                </Label>
            ) : null}
            <input
                onChange={onChange}
                value={props.value}
                name={props.name}
                id={props.id}
                autoFocus={props.autoFocus}
                className={`${styles.input}`}
                type={props.type || "text"}
                data-test-id={props["data-test-id"]}
                placeholder={props.placeholder}
                disabled={props.disabled}
                readOnly={props.readOnly}
                required={props.required}
                max={props.max}
                min={props.min}
            />
            <div className="invalidFeedback" data-invalid-feedback="true"></div>
        </div>
    );
};
