import React from "react";
import { IError } from "../../App";
import { IModule } from "../interfaces/Module";
import { IGiftRegistryReservations, IImage, ISite } from "../interfaces/Site";
import { getBrandedContactMail } from "../utils/getBrand";
import { logError } from "../utils/LogError";
import CountDown from "./CountDown/CountDown";
import { GiftRegistry } from "./GiftRegistry/GiftRegistry";
import Header from "./header/Header";
import Location from "./location/Location";
import { PeopleList } from "./PeopleList/PeopleList";
import { RSVP2 } from "./RSVP2/RSVP2";
import { RSVP3 } from "./RSVP3/RSVP3";
import "./ShowModule.scss";
import Speech from "./Speech/Speech";
import { Speech2 } from "./Speech2/Speech2";
import Text from "./Text/Text";
import TextAndImageList from "./TextAndImageList/TextAndImageList";
import { ContentModuleActionBar } from "../modules/contentModuleActionBar/ContentModuleActionBar";
import Video from "./Video/Video";
import ImageAndText from "./ImageAndText/ImageAndText";

interface IProps {
    moduleData: IModule;
    site: ISite;
    updateProperty: any;
    showAddModule: (module: IModule) => void;
    deleteModule: (module: IModule) => void;
    showOrHideModule: (module: IModule, hide: boolean) => void;
    moveModule: (module: IModule, indexAdjustment: number) => void;
    editMode: boolean;
    editAvailable: boolean;
    addOrUpdateImageToSite: (image: IImage) => void;
    showError: any;
    updateReceiverMail: any;
    updateGiftRegistryReservations: (giftRegistryReservations: IGiftRegistryReservations[]) => void;
}

interface IState {
    actionBarToggled: boolean;
    error: IError | null;
    unhandledClientError: boolean;
}

export class ShowModule extends React.Component<IProps, IState> {
    setActionBarToggled = (actionBarToggled: boolean) => {
        this.setState({
            actionBarToggled,
        });
    };

    state: IState = {
        actionBarToggled: false,
        error: null,
        unhandledClientError: false,
    };

    static getDerivedStateFromError() {
        return { unhandledClientError: true };
    }

    componentDidCatch(error: any, info: any) {
        logError("showModule.componentDidCatch." + this.props.moduleData.module, error, 3, info, this.props.site);
    }

    showError = (errorText: string, response: any) => {
        this.setState({
            error: {
                text: errorText,
                response: response,
            },
        });
    };

    render() {
        if (this.state.unhandledClientError) {
            return (
                <div className="content-module-wrapper-error">
                    Ett oväntat fel uppstod i den här modulen. Spara dina senaste ändringar och ladda om sidan. Om inte
                    det hjälper, kontakta oss på {getBrandedContactMail()}.
                </div>
            );
        }
        return (
            <div className={`${this.state.actionBarToggled ? "highlight" : ""} content-module-wrapper`}>
                <div
                    className={this.props.editAvailable === true ? "scroll-to-id-with-action-bar" : "scroll-to-id"}
                    id={this.props.moduleData.slug}
                ></div>
                {this.props.moduleData.hide === true ? (
                    <div className="module-hidden-info-text">
                        <div>Den här modulen är dold och visas inte för gästerna.</div>
                    </div>
                ) : null}
                {this.props.moduleData.module === "header" && (
                    <Header
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "Text" && (
                    <Text
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "RSVP2" && (
                    <RSVP2
                        site={this.props.site}
                        showError={this.props.showError}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        editAvailable={this.props.editAvailable}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                        updateReceiverMail={this.props.updateReceiverMail}
                    />
                )}
                {this.props.moduleData.module === "RSVP3" && (
                    <RSVP3
                        site={this.props.site}
                        showError={this.props.showError}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        editAvailable={this.props.editAvailable}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                        updateReceiverMail={this.props.updateReceiverMail}
                    />
                )}
                {this.props.moduleData.module === "Speech" && (
                    <Speech
                        site={this.props.site}
                        showError={this.props.showError}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        editAvailable={this.props.editAvailable}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                        updateReceiverMail={this.props.updateReceiverMail}
                    />
                )}
                {this.props.moduleData.module === "Speech2" && (
                    <Speech2
                        site={this.props.site}
                        showError={this.props.showError}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        editAvailable={this.props.editAvailable}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                        updateReceiverMail={this.props.updateReceiverMail}
                    />
                )}
                {this.props.moduleData.module === "location" && (
                    <Location
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "ImageAndText" && (
                    <ImageAndText
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "TextAndImageList" && (
                    <TextAndImageList
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "PeopleList" && (
                    <PeopleList
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "Video" && (
                    <Video
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "countDown" && (
                    <CountDown
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                    />
                )}
                {this.props.moduleData.module === "GiftRegistry" && (
                    <GiftRegistry
                        showError={this.props.showError}
                        site={this.props.site}
                        moduleData={this.props.moduleData}
                        updateProperty={this.props.updateProperty}
                        editMode={this.props.editMode}
                        addOrUpdateImageToSite={this.props.addOrUpdateImageToSite}
                        updateGiftRegistryReservations={this.props.updateGiftRegistryReservations}
                        editAvailable={this.props.editAvailable}
                    />
                )}
                {this.props.editMode === true && (
                    <ContentModuleActionBar
                        module={this.props.moduleData}
                        showOrHideModule={this.props.showOrHideModule}
                        deleteModule={this.props.deleteModule}
                        showAddModule={this.props.showAddModule}
                        moveModule={this.props.moveModule}
                        site={this.props.site}
                        toggled={this.state.actionBarToggled}
                        setActionBarToggled={(toggled: boolean) => this.setActionBarToggled(toggled)}
                    />
                )}
            </div>
        );
    }
}
