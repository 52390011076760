import styles from "./Countdown.module.scss";
import { useEffect, useState } from "react";
import { ISiteV2 } from "../../../../interfaces/Site";
import { ISiteModuleCountdown } from "../../../../interfaces/ISiteModule";
import { calculateCountdown } from "./utils/calculateCountdown";
import { useTranslation } from "../../../../../contexts/LanguageContext";
import { TLanguageCode } from "../../../../interfaces/IContentData";

interface IProps {
    site: ISiteV2;
    siteModule: ISiteModuleCountdown;
    onClick?: () => void;
}

interface ICurrentCountdown {
    days: number;
    hours: number;
    min: number;
    sec: number;
}

interface IDateUnitsTranslation {
    hour: string;
    hours: string;
    minute: string;
    minutes: string;
    second: string;
    seconds: string;
    days: string;
    day: string;
}

const cachedTranslations: Partial<{ [key in TLanguageCode]: IDateUnitsTranslation }> = {};

const addNewLanguage = (locale: TLanguageCode): IDateUnitsTranslation => {
    const translatePlural = (type: "days" | "seconds" | "minutes" | "hours") => {
        try {
            const rtf = new Intl.RelativeTimeFormat(locale, { numeric: "auto" });
            // This is made up by yourself. So this might not work in some languages. If customers reports back. Do it manually or fix it.
            return (
                rtf
                    .formatToParts(100, type)
                    .reverse()
                    .find((item) => item.type === "literal")
                    ?.value.trim() || ""
            );
        } catch (e) {
            return "";
        }
    };

    const translateSingular = (type: "day" | "second" | "minute" | "hour") => {
        try {
            return new Intl.DisplayNames(locale, { type: "dateTimeField" }).of(type) || "";
        } catch (e) {
            return "";
        }
    };

    const translations = {
        days: translatePlural("days"),
        day: translateSingular("day"),
        seconds: translatePlural("seconds"),
        second: translateSingular("second"),
        minute: translateSingular("minute"),
        minutes: translatePlural("minutes"),
        hours: translatePlural("hours"),
        hour: translateSingular("hour"),
    };

    cachedTranslations[locale] = translations;
    return translations;
};

export const Countdown = (props: IProps) => {
    const [currentCountdown, setCurrentCountdown] = useState<ICurrentCountdown>(
        calculateCountdown(props.siteModule.countdownDate)
    );
    const { language } = useTranslation();

    const translateFromNativeJs = (translate: keyof IDateUnitsTranslation, locale: TLanguageCode) => {
        let translations = cachedTranslations[locale];

        if (!translations) {
            translations = addNewLanguage(locale);
        }
        return translations[translate];
    };

    useEffect(() => {
        const updateCountDown = () => {
            setCurrentCountdown(calculateCountdown(props.siteModule.countdownDate));
        };
        const interval = setInterval(updateCountDown, 1000);
        return () => {
            clearInterval(interval);
        };
    }, [props.siteModule.countdownDate]);

    const format = (value: number) => {
        let newValue = String(value);
        return newValue;
    };

    return (
        <div
            className={`${styles[props.site.theme]} ${props.onClick ? styles.editable : ""}`}
            onClick={props.onClick}
        >
            <span className={styles.col}>
                <strong>{format(currentCountdown.days)}</strong>
                <span>{translateFromNativeJs(currentCountdown.days === 1 ? "day" : "days", language)}</span>
            </span>

            <span className={styles.col}>
                <strong>{format(currentCountdown.hours)}</strong>
                <span>
                    <span>{translateFromNativeJs(currentCountdown.hours === 1 ? "hour" : "hours", language)}</span>
                </span>
            </span>

            <span className={styles.col}>
                <strong>{format(currentCountdown.min)}</strong>
                <span>
                    <span>{translateFromNativeJs(currentCountdown.min === 1 ? "minute" : "minutes", language)}</span>
                </span>
            </span>

            <span className={styles.col}>
                <strong>{format(currentCountdown.sec)}</strong>
                <span>
                    <span>{translateFromNativeJs(currentCountdown.sec === 1 ? "second" : "seconds", language)}</span>
                </span>
            </span>
        </div>
    );
};
