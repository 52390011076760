import { useEffect, useState } from "react";
import { IError } from "../../../../../App";
import { trackEvent } from "../../../../../utils/UmamiHelpers";
import Loading from "../../../../components/Loading/Loading";
import ShowError from "../../../../components/ShowError/ShowError";
import { IGiftRegistryReservations, ISite, ISiteV2 } from "../../../../interfaces/Site";
import { InitialHelp } from "../../../../modules/InitialHelp/InitialHelp";
import QuickStartSave from "../../../../modules/QuickStartSave/QuickStartSave";
import { QuickStartSaveConfirmation } from "../../../../modules/QuickStartSaveConfirmation/QuickStartSaveConfirmation";
import {
    getEditQuickStartSite,
    getEditSite,
    postCreateSite,
    postCreateSiteV2,
    putEditSite,
    putEditSiteV2,
} from "../../../../services/SiteService";
import { loadSiteFonts } from "../../../../utils/LoadSiteFonts";
import {
    setGeneratorColorStylesVariables,
    setGeneratorFontsStylesVariables,
} from "../../../../utils/SetGeneratorStylesVariables";
import { Home } from "../../../GeneratorSite/Home";
import { GeneratorSiteEdit } from "../../../GeneratorSite/GeneratorSiteEdit";
import { isSiteV2 } from "../../../../utils/isSiteV2";
import { LanguageProvider } from "../../../../../contexts/LanguageContext";
import { IErrorEdit } from "../../../../interfaces/IErrorV2";
import { ErrorDialogEdit } from "../../../../components/ErrorDialogEdit/ErrorDialogEdit";
import { logError } from "../../../../utils/LogError";

interface IProps {
    editAvailable: boolean;
    showError: any;
    quickStart: boolean;
    showHelpFn: any;
    showHelp: boolean;
    routerHistory: any;
    drawerSlotPortalNode?: JSX.Element;
    siteId?: string;
    host?: string;
}

const appLanguage = "sv";

const isRegisterShortcut = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("registerAccount") === "true";
};

export function EditHome(props: IProps) {
    const [editMode, setEditMode] = useState(true);
    const [isDirty, setIsDirty] = useState(false);

    const [loading, setLoading] = useState(true);
    const [loadingOverlay, setLoadingOverlay] = useState(false);
    const [site, setSite] = useState<ISite | ISiteV2 | null>(null);
    const [error, setError] = useState<IError | null>(null);
    const [errorEdit, setErrorEdit] = useState<IErrorEdit | null>(null);
    const [ignoreWarningNotSaved, setIgnoreWarningNotSaved] = useState(false);

    const [showQuickStartInitialHelp, setShowQuickStartInitialHelp] = useState(
        props.quickStart && !isDirty && !isRegisterShortcut()
    );
    const [showQuickStartSave, setShowQuickStartSave] = useState(isRegisterShortcut() && props.quickStart);
    const [showQuickStartSaveConfirmation, setShowQuickStartSaveConfirmation] = useState(
        props.routerHistory?.location?.state?.showQuickStartSaveConfirmation || false
    );
    const [siteToCreate, setSiteToCreate] = useState<ISite | ISiteV2 | null>(null);

    useEffect(() => {
        const getSite = async () => {
            setLoading(true);
            try {
                let site;
                if (props.quickStart !== true) {
                    if (!props.siteId) {
                        throw new Error("No siteId but not quickstart");
                    }
                    site = await getEditSite(props.siteId);
                } else {
                    site = await getEditQuickStartSite(props.host);
                }
                setSite(site);
                loadSiteFonts(site.fonts);
                setGeneratorFontsStylesVariables(site.fonts);
                setGeneratorColorStylesVariables(site.colorScheme);
            } catch (error) {
                setError({ text: "Misslyckades att hämta sidan", response: (error as any).response });
                if ((error as any)?.response?.status !== 403) {
                    logError("generator.editHome.LoadSite", error, 1, (error as any).response);
                }
            }
            setLoading(false);
        };
        getSite();
    }, [props.siteId, props.host, props.quickStart]);

    if (loading) {
        return <Loading fixed={true} inverted={true} />;
    }

    if (error) {
        return <ShowError error={error} />;
    }

    if (!site) {
        throw Error("Site not loaded but not error");
    }

    const updateSite = (site: ISite, dontTriggerDirty?: boolean) => {
        setSite({ ...site });
        if (!dontTriggerDirty) {
            setIsDirty(true);
        }
    };

    const saveOrCreateSite = (siteToSave?: ISite | ISiteV2) => {
        if (props.quickStart === true) {
            trackEvent("save-button-in-quickstart");
            setShowQuickStartSave(true);
            setSiteToCreate(siteToSave || null);
        } else {
            siteToSave = siteToSave || site;
            if (isSiteV2(siteToSave)) {
                saveSiteV2(siteToSave);
            } else {
                saveSite(siteToSave);
            }
        }
    };

    const saveSiteV2 = async (siteToSave: ISiteV2) => {
        setLoadingOverlay(true);
        try {
            const updatedSite = await putEditSiteV2(siteToSave);
            setSite(updatedSite);
            loadSiteFonts(updatedSite.fonts);
            setGeneratorFontsStylesVariables(updatedSite.fonts);
            setGeneratorColorStylesVariables(updatedSite.colorScheme);
            setIsDirty(false);
        } catch (error) {
            setErrorEdit({ description: "Misslyckades att spara sidan", response: (error as any).response });
            logError("generator.editHome.saveSiteV2", error, 1, {}, siteToSave);
        }
        setLoadingOverlay(false);
    };

    const saveSite = async (siteToSave: ISite) => {
        setLoadingOverlay(true);
        try {
            const updatedSite = await putEditSite(siteToSave);
            setSite(updatedSite);
            loadSiteFonts(updatedSite.fonts);
            setGeneratorFontsStylesVariables(updatedSite.fonts);
            setGeneratorColorStylesVariables(updatedSite.colorScheme);
            setIsDirty(false);
        } catch (error) {
            setError({ text: "Misslyckades att spara sidan", response: (error as any).response });
            logError("generator.editHome.saveSite", error, 1, {}, siteToSave);
        }
        setLoadingOverlay(false);
    };

    const createSite = async () => {
        setLoadingOverlay(true);
        const newSite = siteToCreate || site;
        try {
            let newSiteId;
            if (isSiteV2(newSite)) {
                newSiteId = await postCreateSiteV2(newSite);
            } else {
                newSiteId = await postCreateSite(newSite);
            }
            setIsDirty(false);
            setIgnoreWarningNotSaved(true);
            trackEvent("account-created", { position: "site" });
            setShowQuickStartSave(false);
            setLoadingOverlay(false);
            props.routerHistory.push("/redigera/" + newSiteId + "/", { showQuickStartSaveConfirmation: true });
        } catch (error) {
            setLoadingOverlay(false);
            setError({ text: "Misslyckades att spara sidan", response: (error as any).response });
            logError("generator.editHome.createSite", error, 1, {}, newSite);
        }
    };

    const updateGiftRegistryReservations = (giftRegistryReservations: IGiftRegistryReservations[]) => {
        throw new Error("updateGiftRegistryReservations should not be called in edit mode");
    };

    return (
        <>
            <ErrorDialogEdit error={errorEdit} />
            {showQuickStartInitialHelp === true ? (
                <InitialHelp close={() => setShowQuickStartInitialHelp(false)} />
            ) : null}
            {showQuickStartSave === true ? (
                <QuickStartSave createSite={createSite} close={() => setShowQuickStartSave(false)} site={site} />
            ) : null}
            {showQuickStartSaveConfirmation === true ? (
                <QuickStartSaveConfirmation close={() => setShowQuickStartSaveConfirmation(false)} site={site} />
            ) : null}
            {loadingOverlay ? <Loading fixed={true} /> : null}
            {isSiteV2(site) ? (
                <LanguageProvider defaultLanguage={appLanguage}>
                    <GeneratorSiteEdit
                        routerHistory={props.routerHistory}
                        showHelp={props.showHelp}
                        showHelpFn={props.showHelpFn}
                        quickStart={props.quickStart}
                        site={site}
                        drawerSlotPortalNode={props.drawerSlotPortalNode}
                        saveOrCreateSite={saveOrCreateSite}
                        ignoreWarningNotSaved={ignoreWarningNotSaved}
                    />
                </LanguageProvider>
            ) : (
                <Home
                    routerHistory={props.routerHistory}
                    setIsDirty={setIsDirty}
                    isDirty={isDirty}
                    showHelp={props.showHelp}
                    showHelpFn={props.showHelpFn}
                    toggleEditMode={() => setEditMode(!editMode)}
                    quickStart={props.quickStart}
                    showError={props.showError}
                    editAvailable={props.editAvailable}
                    editMode={editMode}
                    site={site}
                    updateSite={updateSite}
                    drawerSlotPortalNode={props.drawerSlotPortalNode}
                    updateGiftRegistryReservations={updateGiftRegistryReservations}
                    saveOrCreateSite={() => saveOrCreateSite(site)}
                />
            )}
        </>
    );
}
