import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import smoothscroll from "smoothscroll-polyfill";
import "./App.scss";
import ShowError from "./generator/components/ShowError/ShowError";
import { getBrandedDomainName, getBrandedQuickstartHost } from "./generator/utils/getBrand";
import { logError } from "./generator/utils/LogError";
import GeneratorEdit from "./generator/views/GeneratorEdit/GeneratorEdit";
import GeneratorPublic from "./generator/views/GeneratorPublic/GeneratorPublic";
import Landing from "./landing/Landing";
import { getAppTypeFromDomain, getHostFromDomain } from "./utils/DomainHelpers";
import RouterScrollToTop from "./utils/RouterScrollToTop/RouterScrollToTop";
import { View404 } from "./views/View404/View404";
import { ViewError } from "./views/ViewError/ViewError";
import { ViewPdfPrint } from "./generator/views/GeneratorEdit/views/ViewPdfPrint/ViewPdfPrint";

smoothscroll.polyfill();

export interface IError {
    text: string;
    response?: any;
}

interface IState {
    error: IError | null;
    unhandledClientError: boolean;
}

const hostFromDomain = getHostFromDomain();

const appType = getAppTypeFromDomain();

class App extends Component<{}, IState> {
    state: IState = {
        error: null,
        unhandledClientError: false,
    };

    static getDerivedStateFromError() {
        return { unhandledClientError: true };
    }

    componentDidCatch(error: any, info: any) {
        logError("app.componentDidCatch", error, 3, info);
    }

    showError = (errorText: string, response: any) => {
        this.setState({
            error: {
                text: errorText,
                response: response,
            },
        });
    };

    render() {
        if (this.state.unhandledClientError) {
            return <ViewError />;
        }
        return (
            <>
                <Helmet>
                    <meta name="theme-color" content="#2d2d2d" />
                </Helmet>
                <ShowError error={this.state.error} />
                <BrowserRouter>
                    <Switch>
                        {appType === "PUBLIC" ? (
                            <>
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => (
                                        <GeneratorPublic
                                            showError={this.showError.bind(this)}
                                            quickStart={false}
                                            unvalidatedLanguage={null}
                                            host={hostFromDomain as string}
                                            routerHistory={props.history}
                                        />
                                    )}
                                />
                                <Route
                                    path="/:language"
                                    exact
                                    render={(props) => (
                                        <GeneratorPublic
                                            showError={this.showError.bind(this)}
                                            quickStart={false}
                                            unvalidatedLanguage={props.match.params.language}
                                            host={hostFromDomain as string}
                                            routerHistory={props.history}
                                        />
                                    )}
                                />
                            </>
                        ) : null}
                        {appType === "EDIT" ? (
                            <>
                                <Route
                                    path="/demo"
                                    exact
                                    render={(props) => (
                                        <GeneratorPublic
                                            showError={this.showError.bind(this)}
                                            quickStart={false}
                                            unvalidatedLanguage={null}
                                            host={"demo." + getBrandedDomainName()}
                                            routerHistory={props.history}
                                        />
                                    )}
                                />
                                <Route
                                    exact
                                    path="(/logga-in)"
                                    render={(props) => (
                                        <>
                                            <Landing showError={this.showError.bind(this)} {...props} />
                                        </>
                                    )}
                                />
                                <Route
                                    path="/aterstall-losenord/:token?"
                                    render={(props) => (
                                        <>
                                            <Landing showError={this.showError.bind(this)} {...props} />
                                        </>
                                    )}
                                />
                                <Route
                                    path="/redigera/:siteId"
                                    render={(props) => (
                                        <>
                                            <GeneratorEdit
                                                showError={this.showError.bind(this)}
                                                quickStart={false}
                                                siteId={props.match.params.siteId}
                                            />
                                        </>
                                    )}
                                />
                                <Route
                                    path="/print-pdf/:printId/:serverAccessKey"
                                    exact
                                    render={(props) => (
                                        <ViewPdfPrint
                                            printId={props.match.params.printId}
                                            serverAccessKey={props.match.params.serverAccessKey}
                                        />
                                    )}
                                />
                                <Route path="/(sms)?/snabbstart">
                                    <GeneratorEdit
                                        showError={this.showError.bind(this)}
                                        quickStart={true}
                                        host={getBrandedQuickstartHost()}
                                    />
                                </Route>
                            </>
                        ) : null}
                        <Route
                            render={() => (
                                <>
                                    <View404 />
                                </>
                            )}
                        />
                    </Switch>
                    <RouterScrollToTop />
                </BrowserRouter>
            </>
        );
    }
}

export default App;
